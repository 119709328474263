
import { Vue, Options, prop, WithDefault } from 'vue-class-component';
import Icon from '@/components/UIElements/Icon.vue'

class Props {
  placeholder?: string = prop({
    required: false,
  });
  type?: string = prop({
    required: false,
  });
  error?: string = prop({
    required: false,
  });
  hideError?: boolean = prop({
    required: false,
  });
  icon?: string = prop({
    required: false,
  });
  disabled?: boolean = prop({
    required: false,
  });
  disabledBox?: boolean = prop({
    required: false,
  });
  shareButton?: boolean = prop({
    required: false,
  });
  modelValue?: string = prop({
    required: false,
  });
  searchStyle?: boolean = prop({
    required: false,
  });
  plain?: boolean = prop({
    required: false,
  });
  ellipsis: WithDefault<boolean> = prop({
    required: false,
    default: true
  });
  token?: string = prop({
    required: false,
  });
}


@Options({
  components: {
    Icon,
  },
})
export default class CustomTextInput extends Vue.with(Props) {
  isRevealed = false

  handleInput (event:Event) {
    const target = event.target as HTMLInputElement
    this.$emit('update:modelValue', target?.value)
  }
  onClickShare() {
    if (this.modelValue && navigator?.clipboard?.writeText) {
      navigator.clipboard.writeText(this.modelValue).then(() => {
        this.$emit('clickShare', this.modelValue)
      })
    } else {
      this.$emit('clickShare', this.modelValue)
    }
  }

  onClickReveal() {
    this.isRevealed = !this.isRevealed
  }

  get computedType() {
    if(this.type) {
      if(this.type === 'password' && this.isRevealed) {
        return 'text'
      }
      return this.type
    }
    return 'text'
  }
}
