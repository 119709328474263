
import { Vue, Options } from 'vue-class-component';
import CustomButton from '@/components/UIElements/CustomButton.vue'
import Badge from '@/components/UIElements/Badge.vue'
import Icon from '@/components/UIElements/Icon.vue'
import ValidationErrorsModal from '@/components/ValidationErrors/ValidationErrorsModal.vue'
import { APIErrorValidation } from '@/typesAPI';

@Options({
  components: {
    CustomButton,
    Icon,
    Badge,
    ValidationErrorsModal
  },
})
export default class ValidationErrorsBar extends Vue {

  openModal () {
    this.$store.dispatch('errorsValidation/toggleModal')
    this.$store.dispatch('deployment/setModalIsReduced', true)
  }

  get list ():APIErrorValidation[] {
    return this.$store.getters['errorsValidation/getList']
  }

  get errors (): APIErrorValidation[] {
    return this.$store.getters['errorsValidation/getErrors']
  }

  get warnings (): APIErrorValidation[] {
    return this.$store.getters['errorsValidation/getWarnings']
  }

  get infos (): APIErrorValidation[] {
    return this.$store.getters['errorsValidation/getInfos']
  }

  get isLoading ():boolean {
    return this.$store.getters['errorsValidation/isLoading']
  }

  get hidden ():boolean {
    return this.$store.getters['elements/getList'].length === 0 && this.list.length === 0
  }

  get icon ():string {
    if(this.isLoading) {
      return 'spinner'
    } else if (this.list.length) {
      return 'warning'
    } else {
      return 'check-circle'
    }
  }
  get iconColor ():string {
    if(this.isLoading) {
      return 'rgba(0, 0, 58, 1)'
    } else if (this.list.length) {
      return 'rgba(0, 0, 58, 1)'
    } else {
      return '#49DFA5'
    }
  }

}
