
import API from '@/api/wrapper';
import Badge from '@/components/UIElements/Badge.vue';
import Icon from '@/components/UIElements/Icon.vue';
import CustomButton from '@/components/UIElements/CustomButton.vue';
import DropdownMenu from '@/components/UIElements/DropdownMenu.vue';
import Indicator from '@/components/UIElements/Indicator.vue';
import ClusterDisplay from '@/components/UIElements/ClusterDisplay.vue';
import ModalRenameRessource from '@/components/Modals/ModalRenameRessource.vue';
import ModalClusterConfig from '@/components/Modals/ModalClusterConfig.vue';
import ModalClusterPutOffline from '@/components/Modals/ModalClusterPutOffline.vue';
import ModalClusterDelete from '@/components/Modals/ModalClusterDelete.vue';

import { APICluster, APICustomer, APIDeployment, APIProject } from '@/typesAPI';
import { Vue, Options } from 'vue-class-component';
import Logo from '@/components/UIElements/Logo.vue';
import { ListItem } from '@/components/UIElements/List.vue';

@Options({
  components: {
    CustomButton,
    DropdownMenu,
    Badge,
    Icon,
    Indicator,
    ModalRenameRessource,
    Logo,
    ModalClusterConfig,
    ModalClusterPutOffline,
    ModalClusterDelete,
    ClusterDisplay
  },
})
export default class ClustersView extends Vue {

  loadingProjects = false
  actionTarget:APICluster | null = null
  creating = false
  showModalRename = false
  showModalPutOffline = false 
  showModalDelete = false

  get clusters():APICluster[] {
    return this.$store.getters['clusters/getClusters']
  }

  get customer():APICustomer {
    return this.$store.getters['user/getCustomer']
  }

  get projectsList():APIProject[] {
    return this.$store.getters['projects/getProjectList']
  }

  get projects():{[key:string]:APIProject[]} {
    const ret:{[key:string]:APIProject[]} = {}
    this.projectsList.forEach((project) => {
      project?.relationships?.kubernetes_clusters?.data?.forEach((kc) => {
        if(!ret[kc.id]) {
          ret[kc.id] = []
        }
        ret[kc.id].push(project)
      })
    })
    return ret
  }
  get clusterDeployments():APIDeployment[] {
    return this.$store.getters['clusters/getDeployments']
  }

  // Key: clusterId
  // Value: status
  get clustersStatus():{[key:string]:string} {
    const ret:{[key:string]:string} = {}

    this.clusters.forEach((cluster) => {
      const myDeployment = this.clusterDeployments?.find((deployment) => {
        return deployment.id === cluster?.relationships?.deployment?.data?.id
      })
      ret[cluster.id] = myDeployment ? myDeployment.attributes.status : this.$enums.DeploymentStatus.OFFLINE
    })

    return ret
  }

  createCluster() {
    this.actionTarget = null
    this.creating = true
    this.$store.dispatch('modals/setVisibility', {modal:this.$enums.ModalsName.Cluster, newState: true})
  }
  
  onBurgerMenuItemClick(item:ListItem, cluster:APICluster) {
    this.actionTarget = cluster
    this.creating = false
    item.selected = false

    switch(item.id) {
      case 'rename':
        this.showModalRename = true
      break;
      case 'edit':
        this.$store.dispatch('modals/setVisibility', {modal:this.$enums.ModalsName.Cluster, newState: true})
      break;
      case 'offline':
        this.showModalPutOffline = true
      break;
      case 'delete':
        this.showModalDelete = true
      break;
    }
  }
}
