
import { Vue, Options, prop } from 'vue-class-component'
import Modal from '@/components/UIElements/Modal.vue'
import CustomButton from '@/components/UIElements/CustomButton.vue'
import Slider from '@/components/UIElements/Slider.vue'
import SegmentedControl from '@/components/UIElements/SegmentedControl.vue'
import API from '@/api/wrapper'
import CustomSelect from '@/components/UIElements/CustomSelect.vue'
import PriceDisplay from '@/components/UIElements/PriceDisplay.vue'
import Icon from '@/components/UIElements/Icon.vue'
import { ListItem } from '@/components/UIElements/List.vue';
import { APICluster, APIClusterTypologies, APIPriceRanges, APICustomer } from '@/typesAPI'
import { Watch } from 'vue-property-decorator'
import CustomTextInput from '@/components/UIElements/CustomTextInput.vue'



class Props {
  cluster: APICluster | null = prop({
    required: true,
  });
  creating?: boolean = prop({
    required: false,
  });
}

@Options({
  components: {
    Modal,
    CustomButton,
    Slider,
    SegmentedControl,
    CustomSelect,
    Icon,
    CustomTextInput,
    PriceDisplay
  },
})
export default class ModalClusterConfig extends Vue.with(Props) {
  regionsListItems:ListItem[] = []
  regionsActualValue?:ListItem = {}

  name = "New cluster"
  loading = false
  buttonState = ""
  defaultText = "Edit cluster"
  textAccept = this.defaultText
  scallingType = '1'
  maxNbNodes = 6
  nbNodesActualRange = [0,0]
  nodesPerfActualRange = [0,0]
  upgradePlanLoading = false


  @Watch('creating', {immediate: true})
  onCreateChange() {
    if(this.creating) {
      this.defaultText = "Create cluster"
      this.textAccept = this.defaultText
    } else {
      this.defaultText = "Edit cluster"
      this.textAccept = this.defaultText
    }
  }

  onClickTypoCard(typology:APIClusterTypologies, index:number) {
    this.nodesPerfActualRange[0] = index
    this.nodesPerfActualRange[1] = index
  }

  mounted(): void {
    this.init()
  }

  init(): void {
    this.regionsListItems = [
      {
        text: this.$helpers.providers.regionToLabel("nbg1"),
        value: "nbg1"
      },
      {
        text: this.$helpers.providers.regionToLabel("fsn1"),
        value: "fsn1"
      },
      {
        text: this.$helpers.providers.regionToLabel("hel1"),
        value: "hel1"
      },
      {
        text: this.$helpers.providers.regionToLabel("ash"),
        value: "ash"
      },
      {
        text: this.$helpers.providers.regionToLabel("hil"),
        value: "hil"
      },
    ]

    this.nbNodesActualRange = [3,6] //Default sliders value
    this.nodesPerfActualRange = [0,1] //Default sliders value
    this.regionsActualValue = this.regionsListItems[1]    
    API.clusters.getTypologies()
    .then((typos:APIClusterTypologies[]) => {
      this.$store.dispatch('clusters/setTypologies', typos)
    })
  }

  upgradePlan() {
    this.$helpers.subscription.checkout();
    this.upgradePlanLoading = true
  }

  onClose() {
    this.$store.dispatch('modals/setVisibility', {modal:this.$enums.ModalsName.Cluster, newState: false})

    // Reset modal state after fading out
    setTimeout(() => {
      this.init()
    }, 300);
  }

  cancel () {
    this.onClose()
  }

  accept () {
    if(this.cluster) {
      this.loading = true
      API.clusters.editCluster(this.cluster.id, 
        {
          name: this.name,
          requested_config:{
            node_number_max: this.nbNodesActualRange[1],
            node_number_min: this.nbNodesActualRange[0],
            node_size_max:this.typologies[this.nodesPerfActualRange[1]].id,
            node_size_min:this.typologies[this.nodesPerfActualRange[0]].id,
            region: this.regionsActualValue?.value,
          }
      })
      .then((newCluster:APICluster) => {
        this.$store.dispatch('clusters/editCluster', newCluster)

        this.buttonState = this.$enums.ButtonStates.Success
        this.textAccept = "Cluster updated"
        setTimeout(() => {
          this.$store.dispatch('modals/setVisibility', {modal:this.$enums.ModalsName.Cluster, newState: false})
          setTimeout(() => {
            this.buttonState = ""
            this.textAccept = this.defaultText
          },300)
        }, 5000);
      })
      .finally(() => {
        this.loading = false
      })
    } else if(this.creating && this.customer) {
      this.loading = true
      API.clusters.create(this.customer.id, 
        {
          name: this.name,
          requested_config:{
            node_number_max: this.nbNodesActualRange[1],
            node_number_min: this.nbNodesActualRange[0],
            node_size_max:this.typologies[this.nodesPerfActualRange[1]].id,
            node_size_min:this.typologies[this.nodesPerfActualRange[0]].id,
            region: this.regionsActualValue?.value,
          }
      })
      .then((newCluster:APICluster) => {
        this.$store.dispatch('clusters/editCluster', newCluster)

        this.buttonState = this.$enums.ButtonStates.Success
        this.textAccept = "Cluster created"
        setTimeout(() => {
          this.$store.dispatch('modals/setVisibility', {modal:this.$enums.ModalsName.Cluster, newState: false})
          setTimeout(() => {
            this.buttonState = ""
            this.textAccept = this.defaultText
          },300)
        }, 5000);
      })
      .finally(() => {
        this.loading = false
      })
    }
  }


  onSelectRegion(newVal:ListItem) {
    this.regionsActualValue = newVal
  }

  get clusters():APICluster[] {
    return this.$store.getters['clusters/getClusters']
  }

  get customer():APICustomer {
    return this.$store.getters['user/getCustomer']
  }

  get visible():boolean {
    return this.$store.getters['modals/getVisibilityCluster']
  }

  get isAutoScalling():boolean {
    return this.scallingType === '0'
  }

  get typologies():APIClusterTypologies[] {
    return this.$store.getters['clusters/getTypologies']
  }

  get monthlyPrice():APIPriceRanges {
    return this.$store.getters['prices/getMonthlyPrice']
  }

  get totalPriceMin():number {
    const typoPrice = this.typologies[this.nodesPerfActualRange[0]] ? (this.nbNodesActualRange[0] * parseInt(this.typologies[this.nodesPerfActualRange[0]].attributes.price_amount)) : 0
    return typoPrice
  }

  get totalPriceMax():number {
    if(!this.isAutoScalling) {
      return this.totalPriceMin
    }
    const typoPrice = this.typologies[this.nodesPerfActualRange[1]] ? (this.nbNodesActualRange[1] * parseInt(this.typologies[this.nodesPerfActualRange[1]].attributes.price_amount)) : 0
    return typoPrice
  }

  get subscriptionState ():string {
    return this.$store.getters['user/getSubscriptionState']
  }

  get isSubscribed () {
    return this.subscriptionState !== this.$enums.SubscriptionState.FRESH && this.subscriptionState !== this.$enums.SubscriptionState.TRIAL_EXPIRED && this.subscriptionState !== this.$enums.SubscriptionState.EXPIRED
  }

  get isTrialing () {
    return this.subscriptionState === this.$enums.SubscriptionState.TRIALING
  }


  @Watch("cluster", {immediate:true})
  onclusterChange() {
    if(this.cluster) {
      // if(this.cluster?.attributes.requested_config.node_number_min !== this.cluster?.attributes.requested_config.node_number_max) {
      //   this.scallingType = '0'
      // }
      this.nbNodesActualRange = [this.cluster?.attributes.requested_config.node_number_min, this.cluster?.attributes.requested_config.node_number_max]
      const indexPerfMin = this.typologies.findIndex((typo:APIClusterTypologies) => {
        return typo.id === this.cluster?.attributes.requested_config.node_size_min
      })
      const indexPerfMax = this.typologies.findIndex((typo:APIClusterTypologies) => {
        return typo.id === this.cluster?.attributes.requested_config.node_size_max
      })
      this.nodesPerfActualRange = [indexPerfMin, indexPerfMax]

      if(this.cluster?.attributes.region) {
        this.regionsActualValue = this.regionsListItems.find((region:ListItem) => {
          return region.value === this.cluster?.attributes.region
        })
      }
      this.name = this.cluster?.attributes?.name
    } else {
      this.name = "New cluster"
      this.nbNodesActualRange = [3,3] //Default sliders value, if autoscalling available => [3,6]
      this.nodesPerfActualRange = [1,1] //Default sliders value, if autoscalling available => [0,1]
      this.scallingType = "1"
    }
  }

  @Watch("typologies", {immediate:true})
  onTypologiesChange() {
    if(this.typologies?.length) {
      this.onclusterChange()
    }
  }

}
