
import { Options, Vue } from 'vue-class-component';
import CustomButton from '@/components/UIElements/CustomButton.vue';
import Icon from '@/components/UIElements/Icon.vue';
import DropdownMenu from '@/components/UIElements/DropdownMenu.vue';
import PriceDisplay from '@/components/UIElements/PriceDisplay.vue';

import API from '@/api/wrapper';
import { APICluster, APIClusterTypologies, APICustomer, APIPriceRanges, APIProject } from '@/typesAPI';
import { ListItem } from '@/components//UIElements/List.vue';
import { Watch } from 'vue-property-decorator';
import ModalClusterConfig from '@/components/Modals/ModalClusterConfig.vue';
import Spinner from '@/components/UIElements/Spinner.vue';

@Options({
  components: {
    CustomButton,
    Icon,
    DropdownMenu,
    ModalClusterConfig,
    PriceDisplay,
    Spinner
  },
})
export default class ClusterBar extends Vue {

  burgerOpened = false
  burgerItems: ListItem[] = [] 
  selectedClusterId = "" 
  currentClusterId = ""

  get clusterModalOpen():boolean {
    return this.$store.getters['modals/getVisibilityCluster']
  }

  get monthlyPrice():APIPriceRanges | undefined {
    return this.$store.getters['prices/getMonthlyPrice']
  }

  get typologies():APIClusterTypologies[] {
    return this.$store.getters['clusters/getTypologies']
  }

  get displayPriceMin():number {
    const indexTypoMin = this.typologies.findIndex((typo:APIClusterTypologies) => {
      return typo.id === this.selectedCluster?.attributes.requested_config.node_size_min
    })
    return this.typologies[indexTypoMin] && this.monthlyPrice && this.selectedCluster ? (parseInt(this.monthlyPrice.attributes.amount_min)  + (this.selectedCluster?.attributes.requested_config.node_number_min * parseInt(this.typologies[indexTypoMin].attributes.price_amount))) : 0
  }

  get displayPriceMax():number {
    const indexTypoMax = this.typologies.findIndex((typo:APIClusterTypologies) => {
      return typo.id === this.selectedCluster?.attributes.requested_config.node_size_max
    })
    return this.typologies[indexTypoMax] && this.monthlyPrice && this.selectedCluster ? (parseInt(this.monthlyPrice.attributes.amount_max)  + (this.selectedCluster?.attributes.requested_config.node_number_max * parseInt(this.typologies[indexTypoMax].attributes.price_amount))): 0
  }
  
  get customer():APICustomer {
    return this.$store.getters['user/getCustomer']
  }

  get clusters():APICluster[] {
    return this.$store.getters['clusters/getClusters']
  }

  get selectedCluster():APICluster | null {
    const ret = this.clusters?.length ? this.clusters.find(cluster => cluster.id === this.selectedClusterId) : null
    return ret ? ret : null
  }

  get clusterName():string {
    return this.selectedCluster ? this.selectedCluster.attributes.name : ""
  }

  get subscriptionState ():string {
    return this.$store.getters['user/getSubscriptionState']
  }

  get projectList ():APIProject[] | undefined {
    return this.$store.getters["projects/getProjectList"]
  }
  
  get currentProject ():APIProject {
    return this.$store.getters["projects/getCurrentProject"]
  }

  mounted(): void {
    if(!this.monthlyPrice) {
      API.prices.getMonthly(this.$route.params.projectId as string)
      .then ((res:APIPriceRanges) => {
        this.$store.dispatch('prices/setMonthlyPrice', res)
      })   
    }
  }

  openClusterModal() {
    this.$store.dispatch('modals/setVisibility', {modal:this.$enums.ModalsName.Cluster, newState: true})
  }

  onMenuItemClick(item:ListItem) {
    if(item.id === 'edit') {
      this.openClusterModal()
    } else if (item.id?.includes('cluster-')) {
      const clusterId = item.id?.split('cluster-').join('')
      API.projects.edit(this.currentProject.id, {cluster_id: clusterId})
      .then(() => {
        this.currentClusterId = clusterId
        this.selectedClusterId = clusterId
        this.majBurgerItemSelected()
      })
    }

    this.majBurgerItemSelected()

    this.burgerOpened = false
  }

  majBurgerItemSelected() {
    this.burgerItems.forEach((item) => {
      item.selected = this.selectedCluster ? ('cluster-' + this.selectedCluster.id) === item.id : false
      if(!item.selected && item.id?.includes('cluster')) {
        item.disabled = this.currentProject?.attributes.status !== this.$enums.DeploymentStatus.OFFLINE
      }
    })
  }

  @Watch('clusters', {immediate: true, deep:true})
  onClustersChange() {
    const additionalBurgerItems:ListItem[] = []

    if(this.clusters) {
      this.clusters.forEach((cluster) => {
        additionalBurgerItems.push({
          id: 'cluster-'+cluster.id,
          text: cluster.attributes.name
        })
      })
    }

    this.burgerItems = [
      ...additionalBurgerItems,
      {
        id: 'separator',
        separator: true,
      },
      {
        id: 'edit',
        text: 'Edit cluster config',
        icon: 'edit',
      },
    ]

    this.majBurgerItemSelected() 
  }

  @Watch('projectList', {immediate: true})
  onProjectListChange() {
    if(this.projectList) {
      const currentProject = this.projectList.find(project => project.id === this.$store.getters['projects/getCurrentProjectId'])
      if(currentProject?.relationships?.kubernetes_clusters?.data?.length) {
        this.selectedClusterId = currentProject?.relationships?.kubernetes_clusters?.data[0].id
        this.currentClusterId = currentProject?.relationships?.kubernetes_clusters?.data[0].id
        this.majBurgerItemSelected()
      }
    }
  }

  @Watch('currentProject', {immediate: true, deep:true})
  onCurrentProjectChange() {
    this.majBurgerItemSelected()
  }

}
