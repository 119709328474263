
import { Vue, Options } from 'vue-class-component'
import Logo from '@/components/UIElements/Logo.vue'
import Badge from '@/components/UIElements/Badge.vue'
import CustomTextInput from '@/components/UIElements/CustomTextInput.vue'
import { Watch } from 'vue-property-decorator'
import API from '@/api/wrapper'

@Options({
  components: {
    Logo,
    Badge,
    CustomTextInput
  },
})
export default class ProjectBar extends Vue {

  showModalRenameProject = false
  newName = ""

  // FIRST UNBOARDING
  get collapsed () {
    return !this.$store.getters['app/getFirstUnboardingDone']
  }
  // END FIRST UNBOARDING


  get currentProject() {
    return this.$store.getters['projects/getCurrentProject']
  }

  @Watch('currentProject', {immediate: true, deep: true})
  onCurrentProjectChange() {
    if(this.currentProject) {
      this.newName = this.currentProject?.attributes?.name
    }
  }

  doNameChange() {
    if(this.newName !== this.currentProject.attributes.name) {
      API.projects.edit(this.currentProject.id, {name:this.newName})
      .then((newVal) => {
        this.$store.dispatch('projects/updateProject', newVal)
      }) 
    }
    
  }
}
