import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/illustrations/empty_box.svg'


const _hoisted_1 = { class: "projectsView full-view" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_CustomButton = _resolveComponent("CustomButton")!
  const _component_ProjectsSneakPeek = _resolveComponent("ProjectsSneakPeek")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.projectsList?.length === 0)
      ? (_openBlock(), _createBlock(_component_v_col, {
          key: 0,
          class: "pa-0"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, { class: "ma-0" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, { class: "pa-0 token-text-h4" }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Projects ")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_row, { class: "ma-0 pt-4" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, { class: "pa-4 empty-box" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_row, { class: "ma-0 d-flex-row" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, { class: "pa-0 ma-auto fit-content fit-content-height d-flex-col" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_row, { class: "ma-0 mx-auto fit-content-height" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_img, {
                                  src: _imports_0,
                                  height: "77px",
                                  width: "98px"
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_row, { class: "ma-0 token-text-h5 my-4 mx-auto fit-content-height" }, {
                              default: _withCtx(() => [
                                _createTextVNode(" No project ")
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_row, { class: "ma-0 mx-auto fit-content-height" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_CustomButton, {
                                  text: "Create project",
                                  onClick: _ctx.createProject
                                }, null, 8, ["onClick"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : (_openBlock(), _createBlock(_component_v_col, {
          key: 1,
          class: "pa-0"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, { class: "ma-0" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, { class: "pa-0 token-text-h4" }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Projects ")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_col, { class: "pa-0 ml-auto fit-content" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_CustomButton, {
                      icon: "add",
                      text: "Create project",
                      onClick: _ctx.createProject
                    }, null, 8, ["onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_row, { class: "ma-0 mx-n6 pt-4" }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.projectsList, (project, index) => {
                  return (_openBlock(), _createBlock(_component_v_col, {
                    class: "pa-4",
                    cols: "6",
                    key: project.id
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ProjectsSneakPeek, {
                        project: _ctx.projectsList[index],
                        "onUpdate:project": ($event: any) => ((_ctx.projectsList[index]) = $event)
                      }, null, 8, ["project", "onUpdate:project"])
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
  ]))
}