
import { Options, Vue } from 'vue-class-component';
import SaveBar from '@/components/Actions/SaveBar.vue';

@Options({
  components: {
    SaveBar
  },
})
export default class PanelRight extends Vue {
 
}
