
import API from '@/api/wrapper'
import { APIBlock, APIConnection, APIProject } from '@/typesAPI'
import { Vue, Options, prop } from 'vue-class-component'
import { VueFlow, useVueFlow } from '@vue-flow/core'
import { Background, BackgroundVariant } from '@vue-flow/additional-components'
import BlockNode from '@/components/Nodes/BlockNode.vue'
import CustomEdge from '@/components/Edges/CustomEdge.vue'
import { Element, ElementNode } from '@/types'
import Badge from '@/components/UIElements/Badge.vue'
import Spinner from '@/components/UIElements/Spinner.vue'
import { ListItem } from '@/components/UIElements/List.vue'
import CustomButton  from '@/components/UIElements/CustomButton.vue'
import DropdownMenu  from '@/components/UIElements/DropdownMenu.vue'
import CustomTextInput from '@/components/UIElements/CustomTextInput.vue'
import ModalRenameRessource from '@/components/Modals/ModalRenameRessource.vue'
import ModalProjectPutOffline from '@/components/Modals/ModalProjectPutOffline.vue'
import ModalProjectDelete from '@/components/Modals/ModalProjectDelete.vue'
import { Watch } from 'vue-property-decorator'

class Props {
  project: APIProject = prop({
    required: true,
  })
}

@Options({
  components: {
    VueFlow,
    Background,
    CustomEdge,
    BlockNode,
    Badge,
    Spinner,
    CustomButton,
    DropdownMenu,
    ModalRenameRessource,
    ModalProjectPutOffline,
    ModalProjectDelete,
    CustomTextInput
  },
})
export default class LogoBar extends Vue.with(Props) {
  vueFlowState = useVueFlow()
  BackgroundVariant:typeof BackgroundVariant = BackgroundVariant

  blocks:APIBlock[] = []
  connections:APIConnection[] = []
  elements:Element[] = []
  burgerOpened:{[key:string]:boolean} = {}
  burgerItems: ListItem[] = [
    {
      id: 'rename',
      text: 'Rename',
      icon: 'edit'
    },
    {
      id: 'offline',
      text: 'Shut down',
      icon: 'cloud-off'
    },
    
    {
      id: 'separator',
      separator: true,
    },
    {
      id: 'delete',
      text: 'Delete',
      icon: 'trash',
      class: 'token-text-color-orange'
    },
  ]  
  loading = false
  showModalRenameProject = false
  showModalProjectPutOffline = false
  showModalProjectDelete = false

  mounted(): void {
    if(this.project.id) {
      this.loadDatas()
    }
  }

  @Watch('project', {immediate: true, deep: true})
  onProjectChange() {
    if( this.project ) {
      this.burgerItems.forEach((item) => {
        if(item.id === 'offline') {
          item.disabled = this.project?.attributes?.status === this.$enums.DeploymentStatus.OFFLINE || this.project?.attributes?.status === this.$enums.DeploymentStatus.SHUTTING_DOWN
        } else if(item.id === 'delete') {
          item.disabled = this.project?.attributes?.status !== this.$enums.DeploymentStatus.OFFLINE
        }
      })
    }
  }

  onBurgerMenuItemClick(item:ListItem) {

    switch(item.id) {
      case 'rename':
        this.showModalRenameProject = true
      break;
      case 'offline':
        this.showModalProjectPutOffline = true
      break;
      case 'delete':
        this.showModalProjectDelete = true
      break;
    }

    item.selected = false
    this.burgerOpened[this.project.id] = false
  }

  loadDatas () {
    this.loading = true
    API.blocks.getIndex(this.project.id, "current_connections")
    .then((result) => {
      this.blocks = result.blocks
      this.connections = result.connections

      this.blocks.forEach((blockAPI:APIBlock) => {
        const block = this.$store.getters['blocks/getByID'](blockAPI.relationships?.category?.data?.id)
        const newElementNode:Element = {
          id: blockAPI.id,
          label: blockAPI.attributes.name,
          position:{ x: blockAPI.attributes.longitude, y: blockAPI.attributes.latitude },
          type: 'block',
          extra: {
            block: block,
            blockAPIID: blockAPI.id
          }
        }
        this.elements.push(newElementNode)
      })

      const storeNodes:ElementNode[] = this.elements.filter((elem:any) => {
        if(elem.type === 'block') return elem
      }) as ElementNode[]
      this.connections.forEach((connectionAPI:APIConnection) => {
        const leftNode:ElementNode | undefined = storeNodes.find((node:ElementNode) => {
          return node.extra.blockAPIID === connectionAPI.relationships.left.data.id
        })
        const rightNode:ElementNode | undefined = storeNodes.find((node:ElementNode) => {
          return node.extra.blockAPIID === connectionAPI.relationships.right.data.id
        })
        if(leftNode && rightNode) {
          this.elements.push({
            id: connectionAPI.id,
            source: leftNode.id,
            target: rightNode.id,
            sourceHandle: leftNode.id + '__handle-'+ this.getClosestHandleDirection(leftNode.id, rightNode.id),
            targetHandle: rightNode.id + '__handle-'+ this.getClosestHandleDirection(rightNode.id, leftNode.id),
            type: 'link'
          })
        }
      })
      

      // this.$store.dispatch('blocksAPI/setBlockList', result.blocks)
      // this.$store.dispatch('elements/setNodesFromAPIBlocks', result.blocks)
      // this.$store.dispatch('elements/setLinksFromAPIConnections', result.connections)
    })
    .finally(() => {
      this.loading = false
    })
  }


  getClosestHandleDirection(nodeSrcId: string, nodeTargetId: string): string {
    const nodes = this.elements
    const nodeSrc = nodes.find((node) => node.id === nodeSrcId) as ElementNode
    const nodeTarget = nodes.find((node) => node.id === nodeTargetId) as ElementNode
    let closestDirection = ''
    const nodeWidth = 116
    const nodeHeight = 40
    if(nodeSrc && nodeTarget) {
      const srcTop = {x : nodeSrc.position.x + nodeWidth/2, y: nodeSrc.position.y}
      const srcBottom = {x : nodeSrc.position.x + nodeWidth/2, y: nodeSrc.position.y + nodeHeight}
      const srcRight = {x : nodeSrc.position.x  + nodeWidth, y: nodeSrc.position.y + nodeHeight/2}
      const srcLeft = {x : nodeSrc.position.x, y: nodeSrc.position.y + nodeHeight/2}
    
      const distTop = Math.hypot(nodeTarget.position.x - srcTop.x, nodeTarget.position.y - srcTop.y)
      const distRight = Math.hypot(nodeTarget.position.x - srcRight.x, nodeTarget.position.y - srcRight.y)
      const distBottom = Math.hypot(nodeTarget.position.x - srcBottom.x, nodeTarget.position.y - srcBottom.y)
      const distLeft = Math.hypot(nodeTarget.position.x - srcLeft.x, nodeTarget.position.y - srcLeft.y)
    
      const minDist = Math.min(distTop, distRight, distBottom, distLeft)
    
      if(minDist === distTop) {
        closestDirection = 'top'
      } else if(minDist === distRight) {
        closestDirection = 'right'
      } else if(minDist === distBottom) {
        closestDirection = 'bottom'
      } else if(minDist === distLeft) {
        closestDirection = 'left'
      } 
    }
  
    return closestDirection
  }

  onClick() {
    this.$router.push(
      {
        name:'flow',
        params: {
          ...this.$route.params,
          projectId: this.project.id
        }
      }
    )  
  }
}
