
import CustomButton from '@/components/UIElements/CustomButton.vue';
import CustomTextInput from '@/components/UIElements/CustomTextInput.vue';
import TextBetweenLines from '@/components/UIElements/TextBetweenLines.vue';
import LoginSocialButton from '@/components/Actions/LoginSocialButton.vue';
import { Vue, Options, prop } from 'vue-class-component';
import API from '@/api/wrapper'
import { APICustomer, APISocialAccount, APIUser } from '@/typesAPI';

class Props {
  withPassword?: boolean = prop({
    required: false,
  });
}

@Options({
  components: {
    CustomButton,
    CustomTextInput,
    LoginSocialButton,
    TextBetweenLines
  },
})
export default class LoginBar extends Vue.with(Props) {
  login = ''
  password = '' 
  loading = false
  btnText = 'Log in / Sign up'
  errors = {
    login: "",
    password: ""
  }
  buttonState = ''
  displayGenericError = false

  mounted() {
    API.session.getCSRF().then(() => {
      this.getMe()
    })
  }

  getMe() {
    API.user.getMe()
    .then((res:{user:APIUser, socialAccounts:APISocialAccount[], customers:APICustomer[]}) => {
      this.$store.dispatch('user/setUser', res.user)
      this.$store.dispatch('user/setCustomer', res.customers[0])
      res.socialAccounts.forEach((socialAccount:APISocialAccount) => {
        this.$store.dispatch('user/setSocialAccount', {provider: socialAccount.attributes.provider, socialAccount: socialAccount})
      })
      this.$router.push({name:'home'})
    })
  }

  doLogin () {
    if(this.login) {
      if(this.withPassword && this.password) {
        this.loading = true
        this.btnText = ""
        this.displayGenericError = false
        API.session.login(this.login, this.password)
        .then((res:any) => {
          this.loading = false
          this.buttonState = this.$enums.ButtonStates.Success
          this.getMe()
        })
        .catch((err:any) => {
          if(err?.response?.data) {
            this.buttonState = this.$enums.ButtonStates.Danger
            this.loading = false
            setTimeout(() => {
              this.buttonState = ''
              this.btnText = 'Log in'
            },2000)

            this.errors.login = err?.response?.data?.meta?.login ? err.response.data.meta.login.join(' ') : ''
            this.errors.password = err?.response?.data?.meta?.password ? err.response.data.meta.password.join(' ') : ''
            if(err.response.status === 403) {
              this.displayGenericError = true
            }
          }
          
        })
      } else {
        this.$router.push({name:'register', query: {email: this.login}})
      }
    }
  }


}
