
import { Vue, Options, prop } from 'vue-class-component'
import Modal from '@/components/UIElements/Modal.vue'
import CustomButton from '@/components/UIElements/CustomButton.vue'
import CustomTextInput from '@/components/UIElements/CustomTextInput.vue'
import API from '@/api/wrapper';
import { Watch } from 'vue-property-decorator';
import { APIProject } from '@/typesAPI';
import Checkbox from '@/components/UIElements/Checkbox.vue';
import Divider from '@/components/UIElements/Divider.vue';

class Props {
  visible: boolean = prop({
    required: true,
  });
  cluster: APIProject = prop({
    required: true,
  });
  projects: APIProject[] = prop({
    required: true,
  });
}


@Options({
  components: {
    Modal,
    CustomButton,
    CustomTextInput,
    Checkbox,
    Divider
  },
})
export default class ModalClusterPutOffline extends Vue.with(Props) {
  loading = false
  confirmText = ""
  checks:{[key:string]:boolean} = {}

  get disabled():boolean {
    return this.confirmText !== this.cluster?.attributes?.name || !this.isAllCheck
  }

  get isAllCheck():boolean {
    if(!this.projects?.length) {
      return true
    }
    let ret = true
    Object.values(this.checks).forEach((val) => {
      if(!val) {
        ret = false
      }
    })

    return Object.keys(this.checks).length === this.projects?.length && ret
  }

  closeModal() {
    this.$emit('update:visible', false)
  }

  doPutOffline() {
    this.loading = true

    API.clusters.putOffline(this.cluster.id, Object.keys(this.checks))
    .then(() => {
      this.loading = false
      this.$emit('update:visible', false)
    }) 

  }

  @Watch('visible', {immediate: true})
  onVisibleChange() {
    if(this.visible) {
      this.confirmText = ""
      this.checks = {}
      this.projects?.forEach((project) => {
        this.checks[project.id] = false
      })
    }
  }

}
