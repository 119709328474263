
import { Vue } from 'vue-class-component';
import API from '@/api/wrapper';
import { APICustomer, APISocialAccount, APIUser } from "@/typesAPI"

export default class SocialAccountCallbackView extends Vue {

  socialAccountProvider = ""

  mounted(): void {

    this.socialAccountProvider = this.$route.params.socialProvider.toString()

    API.user.getMe()
    .then((res:{user:APIUser, socialAccounts:APISocialAccount[], customers:APICustomer[]}) => {
      this.$store.dispatch('user/setUser', res.user)
      this.$store.dispatch('user/setCustomer', res.customers[0])
      res.socialAccounts.forEach((socialAccount:APISocialAccount) => {
        this.$store.dispatch('user/setSocialAccount', {provider: socialAccount.attributes.provider, socialAccount: socialAccount})
      })

      const origin = window.location.origin
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.delete('code')




      const splitedState = urlParams.get('state')?.split('|')
      const tabState:{[key:string]: string} = {}
      const keys = ['projectId', 'blockId', 'tabId', 'boxId']
      splitedState?.forEach((val:string) => {
        for(const key of keys) {
          const temp = val.split(`${key}=`)
          if(temp[1]) {
            tabState[key] = temp[1]
          }
        }
      })
      const paramsProjectIdOrigin = tabState['projectId'] ? `projectId=${tabState['projectId']}` : ''
      const paramsBlockIdOrigin = tabState['blockId'] ? `&blockId=${tabState['blockId']}` : ''
      const paramsTabIdOrigin = tabState['tabId'] ? `&tabId=${tabState['tabId']}` : ''
      const paramsBoxIdOrigin = tabState['boxId'] ? `&boxId=${tabState['boxId']}` : ''

      const redirectUrl = `${origin}${window.location.pathname}?${urlParams.toString()}`


      API.social.callback(this.$store.getters['user/getUser'].id, this.socialAccountProvider, this.$route.query.code as string, redirectUrl)
      .then((socialAccount:APISocialAccount) => {
        this.$store.dispatch('user/setSocialAccount', {provider: this.socialAccountProvider, socialAccount: socialAccount})
      })
      .finally(() => {
        this.$router.replace({
          name: 'flow'+ (tabState['blockId'] ? 'Block' : '') + (tabState['blockId'] ? 'AndBox' : ''),
          params: {
            ...this.$route.params,
            projectId : tabState['projectId'] ? tabState['projectId'] : this.$store.getters['projects/getCurrentProjectId'],
            boxId : tabState['boxId'],
            blockId : tabState['blockId'],
            tabId : tabState['tabId']
          },
        });
      })
    })
    .catch(() => {
      this.$router.push({name: 'login'})
    })
  }
}
