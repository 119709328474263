
import { Options, Vue, prop } from 'vue-class-component';
import CustomButton from '@/components/UIElements/CustomButton.vue';
import DropdownMenu from '@/components/UIElements/DropdownMenu.vue';
import Icon from '@/components/UIElements/Icon.vue';
import Logo from '@/components/UIElements/Logo.vue';
import Badge from '@/components/UIElements/Badge.vue';
import { ListItem } from '@/components/UIElements/List.vue';
import { APICluster, APIProject } from '@/typesAPI';
import { Watch } from 'vue-property-decorator';

class Props {
  cluster:APICluster = prop({
    required: true,
  });
  projects:APIProject[] = prop({
    required: true,
  });
  clustersStatus:string = prop({
    required: true,
  });
}


@Options({
  components: {
    CustomButton,
    DropdownMenu,
    Icon,
    Logo,
    Badge
  },
})
export default class ClusterDisplay extends Vue.with(Props) {
  burgerOpened = false
  burgerItems: ListItem[] = [
    {
      id: 'rename',
      text: 'Rename',
      icon: 'edit'
    },
    {
      id: 'edit',
      text: 'Edit config',
      icon: 'edit'
    },
    {
      id: 'offline',
      text: 'Shut down',
      icon: 'cloud-off'
    },
    {
      id: 'separator',
      separator: true,
    },
    {
      id: 'delete',
      text: 'Delete',
      icon: 'trash',
      class: 'token-text-color-orange'
    },
  ]  

  onBurgerMenuItemClick(item:ListItem, cluster:APICluster) {
    this.$emit('clickMenu', item, cluster)
    this.burgerOpened = false
  }

  disabledItemsMenu() {
    this.burgerItems.forEach((item) => {
      if(item.id === 'offline') {
        item.disabled = this.clustersStatus === this.$enums.DeploymentStatus.OFFLINE || this.clustersStatus === this.$enums.DeploymentStatus.SHUTTING_DOWN
      } else if(item.id === 'delete') {
        item.disabled = this.clustersStatus !== this.$enums.DeploymentStatus.OFFLINE
      }
    })
  }

  @Watch('cluster', {immediate: true, deep: true})
  onClusterChange() {
    if( this.cluster ) {
      this.disabledItemsMenu()
    }
  }

  @Watch('clustersStatus', {immediate: true, deep: true})
  onClustersStatusChange() {
    if( this.clustersStatus ) {
      this.disabledItemsMenu()
    }
  }

}
