
import { Vue, prop } from 'vue-class-component';

class Props {
  values: number[]  = prop({
    required: true,
  });
  colors?: string[]  = prop({
    required: false,
  });
}

export default class FlatGauge extends Vue.with(Props) {

  //{0-100}[]
  get sizes():number[] {
    const min = this.values[0]
    const max = Math.max(...this.values)
    const range = max - min
    const ret:number[] = []
    let lastVal = min
    this.values.forEach((value:number, index:number) => {
      if(value > max) {
        value = max
      }
      const diff = value - lastVal
      ret.push((diff/range) * 100)
      lastVal = value
    })
    return ret
  }

}
