
import { Vue, Options, prop } from 'vue-class-component'
import Modal from '@/components/UIElements/Modal.vue'
import CustomButton from '@/components/UIElements/CustomButton.vue'
import CustomTextInput from '@/components/UIElements/CustomTextInput.vue'
import API from '@/api/wrapper';
import { Watch } from 'vue-property-decorator';
import { APIProject } from '@/typesAPI';
import Divider from '@/components/UIElements/Divider.vue';

class Props {
  visible: boolean = prop({
    required: true,
  });
  project: APIProject = prop({
    required: true,
  });
}


@Options({
  components: {
    Modal,
    CustomButton,
    CustomTextInput,
    Divider
  },
})
export default class ModalProjectDelete extends Vue.with(Props) {
  loading = false
  confirmText = ""

  get disabled():boolean {
    return this.confirmText !== this.project.attributes.name
  }

  closeModal() {
    this.$emit('update:visible', false)
  }

  doDelete() {
    this.loading = true

    API.projects.delete(this.project.id)
    .then(() => {
      this.$store.dispatch('projects/deleteProject', this.project)
      this.loading = false
      this.$emit('update:visible', false)
    }) 

  }

  @Watch('visible', {immediate: true})
  onVisibleChange() {
    if(this.visible) {
      this.confirmText = ""
    }
  }

}
