
import { Vue, Options } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import DeploymentModal from '@/components/Deployment/DeploymentModal.vue'

@Options({
  components: {
    DeploymentModal
  },
})
export default class SaveBar extends Vue {
  

  // DEPLOY SIZE
  get isDeployementModalOpen ():boolean {
    return this.$store.getters['deployment/getModalIsOpen']
  }
  get isDeployementModalReduced ():boolean {
    return this.$store.getters['deployment/getModalIsReduced']
  }
  // END DEPLOY SIZE



  // FIRST UNBOARDING
  waitingForUnboarding = false
  get hideLeft () {
    return !this.$store.getters['app/getFirstUnboardingDone']
  }

  get firstUnboardingOngoing () {
    return this.$store.getters['app/getFirstUnboardingDone'] && this.waitingForUnboarding
  }

  get deploying (): boolean {
    return this.$store.getters['deployment/getDeployingState'] === this.$enums.DeploymentState.Running
  }

  get deployed (): boolean {
    return this.$store.getters['deployment/getDeployingState'] === this.$enums.DeploymentState.Completed
  }

  @Watch('hideLeft')
  onChangeHideLeft(newVal:boolean) {
    if(!newVal) {
      this.waitingForUnboarding = true
      setTimeout(() => {
        this.waitingForUnboarding = false
      },200)
    }
  }
  // END FIRST UNBOARDING
}
