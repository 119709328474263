import { resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomTextInput = _resolveComponent("CustomTextInput")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_col = _resolveComponent("v-col")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["project-bar bar px-2 d-flex", {collapsed:_ctx.collapsed}])
  }, [
    _createVNode(_component_v_col, { class: "ma-auto pa-0" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, { class: "pa-0 ma-0 d-flex" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { class: "pa-0 my-auto" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_row, { class: "ma-0 title-container full-width" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_CustomTextInput, {
                      modelValue: _ctx.newName,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newName) = $event)),
                      class: "mr-0",
                      token: 'token-text-medium',
                      plain: true,
                      onBlur: _ctx.doNameChange,
                      onKeypress: _withKeys(_ctx.doNameChange, ["enter"])
                    }, null, 8, ["modelValue", "onBlur", "onKeypress"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 2))
}