import enums from '@/enums'
import { APICluster, APIClusterTypologies, APIDeployment } from '@/typesAPI'
import helpers from '@/utils/helpers'
import store from '..'
import API from '@/api/wrapper'

type State = {
  clusterList: APICluster[] | null,
  typologies:APIClusterTypologies[]
  deployments:APIDeployment[]
}

const state: State = {
  clusterList: null,
  typologies:[],
  deployments: []
}


const getters = {
  getClusters: (state:State) => {
    if(state.clusterList === null && store.getters['user/getCustomer']?.id) {
      API.clusters.getClustersByCustomer(store.getters['user/getCustomer']?.id)
      .then((res) => {
        state.clusterList = res.clusters
        state.deployments = res.deployments
      })
      return []
    }
    return state.clusterList
  },
  getClusterById: (state:State) => {
    return (id:string) => {
      const clusterList = store.getters['clusters/getClusters']
      return clusterList ? clusterList.find((obj:APICluster) => obj.id === id) : null
    }
  },
  getTypologies: (state:State) => {
    return state.typologies
  },
  getDeployments: (state:State) => {
    return state.deployments
  },
}

const mutations = {
  SET_CLUSTERS: (state:State, clusters:APICluster[]) => {
    state.clusterList = clusters
  },
  EDIT_CLUSTER: (state:State, cluster:APICluster) => {
    if(state.clusterList) {
      helpers.ressources.addMultipleRessourceToArray(state.clusterList, [cluster])
    } else {
      state.clusterList = [cluster]
    }
  },
  DELETE_CLUSTER: (state:State, cluster:APICluster) => {
    if(state.clusterList) {
      state.clusterList = state.clusterList.filter((c) => c.id !== cluster.id)
    }
  },
  SET_TYPOLOGIES: (state:State, typologies:APIClusterTypologies[]) => {
    state.typologies = typologies
  },
  EDIT_DEPLOYMENT: (state:State, deployment:APIDeployment) => {
    helpers.ressources.addMultipleRessourceToArray(state.deployments, [deployment])
  },
  DELETE_DEPLOYMENT: (state:State, deployment:APIDeployment) => {
    state.deployments = state.deployments.filter((d) => d.id !== deployment.id)
  },
}

const actions = {
  setClusters: (context:any,clusters:APICluster[]) => {
    context.commit('SET_CLUSTERS', clusters)
  },
  setTypologies: (context:any,typologies:APIClusterTypologies[]) => {
    context.commit('SET_TYPOLOGIES', typologies)
  },
  editCluster: (context:any,cluster:APICluster) => {
    context.commit('EDIT_CLUSTER', cluster)
  },
  deleteCluster: (context:any,cluster:APICluster) => {
    context.commit('DELETE_CLUSTER', cluster)
  },
  editDeployment: (context:any,deployment:APIDeployment) => {
    context.commit('EDIT_DEPLOYMENT', deployment)
  },
  deleteDeployment: (context:any,deployment:APIDeployment) => {
    context.commit('DELETE_DEPLOYMENT', deployment)
  },
}
export default {
  state,
  getters,
  mutations,
  actions
}